import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React from 'react';

import HeroFilters from './HeroFilters';
import { Hero2CosmicMetadata } from './metaData';

type Props = {
  data: Hero2CosmicMetadata;
  title: string;
  imgURL: string;
};

export const HeroExperiment: React.FC<Props> = ({ data, title, imgURL }) => {
  if (!data) return null;

  const srcSet = getCloudinarySrcSet(imgURL, [480, 800, 1200, 1600]);
  const src = getCloudinaryImgUrl(imgURL, { w: 1200, dpr: 2 });

  return (
    <div
      className={
        'relative h-[622px] bg-gradient-to-b from-black/[8%] to-black/0 md:h-[732px]'
      }
    >
      <img
        draggable={false}
        src={src}
        srcSet={srcSet}
        alt="Hero"
        className={`-z-overlay absolute inset-0 h-full w-full object-cover`}
      />
      <div className="container" data-testid="hero2">
        <div className="-mb-10 grid gap-4 sm:grid-cols-[400px_1fr] md:-mb-24 md:grid-cols-[500px_1fr]">
          <div className="mt-[124px] w-full">
            <h1
              className="mobile-t2-semibold md:web-t2-semibold mb-6 text-left text-white"
              data-cy="hero-title"
            >
              {title}
            </h1>

            <HeroFilters primaryCTA={data.primary_cta} isHeroExperiment />
          </div>
        </div>
      </div>
    </div>
  );
};
