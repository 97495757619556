import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import {
  FilterKey,
  FILTERS_RESPONSE_SWR_FALLBACK_KEY,
  PathFilterProvider,
  trackFilterOptionClicked,
  useAvailableFilters,
  useTrackFilterCategoryClicked,
} from '@finn/ua-vehicle';
import { cn, slugify, useIntl } from '@finn/ui-utils';
import React, { useMemo, useState } from 'react';
import { SWRConfig } from 'swr';

import { CTAData } from '~/types/UIModules';
import CTAs from '~/ui-modules/Hero2/CTAs';

import filters from '../../localization/available.filters.json';

type Props = {
  primaryCTA: CTAData;
  isHeroExperiment?: boolean;
};

const HeroFilterComponent: React.FC<Props> = ({
  primaryCTA,
  isHeroExperiment,
}) => {
  const i18n = useIntl();

  const brandPlaceholder = i18n.formatMessage('general.heroFilters.allBrands');
  const modelPlaceholder = i18n.formatMessage('general.heroFilters.allModels');

  const [brand, setBrand] = useState<string>(brandPlaceholder);
  const [model, setModel] = useState<string>(modelPlaceholder);

  const { brands } = useAvailableFilters({
    filters: {},
  });

  const adjustedCTA = useMemo(() => {
    const adjustedBrand = brand === brandPlaceholder ? '' : brand;
    const adjustedModel = model === modelPlaceholder ? '' : model;

    const originalCTAHref = primaryCTA.metadata.href;
    const modelPath = adjustedModel ? `_${slugify(adjustedModel)}` : '';

    const updatedHref = brand
      ? `${originalCTAHref}/${slugify(adjustedBrand)}${modelPath}`
      : originalCTAHref;

    return {
      ...primaryCTA,
      metadata: {
        ...primaryCTA.metadata,
        text: i18n.formatMessage('general.heroFilters.cta'),
        href: updatedHref,
      },
    };
  }, [brand, model, brandPlaceholder, modelPlaceholder]);

  const handleBrandChange = (value: string) => {
    setBrand(value);
    setModel(modelPlaceholder);
    trackFilterOptionClicked(FilterKey.BRANDS, value);
  };

  const handleModelChange = (value: string) => {
    setModel(value);
    trackFilterOptionClicked(FilterKey.MODELS, value);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-4 lg:flex-row',
        isHeroExperiment && 'max-w-[410px] lg:flex-col'
      )}
    >
      <div
        className={cn(
          'flex flex-grow flex-col gap-4 md:flex-row',
          isHeroExperiment && 'md:flex-col'
        )}
      >
        <Select
          value={brand}
          onValueChange={(value) => handleBrandChange(value)}
          onOpenChange={useTrackFilterCategoryClicked(FilterKey.BRANDS)}
        >
          <SelectTrigger
            wrapperClassName="w-full"
            label={i18n.formatMessage('plp.brand')}
            className="w-full"
          >
            <SelectValue
              placeholder={brandPlaceholder}
              className="body-16-regular"
            />
          </SelectTrigger>
          <SelectContent className="max-h-[340px]" withScrollDownButton>
            <SelectItem value={brandPlaceholder}>
              <span>{brandPlaceholder}</span>
            </SelectItem>
            {brands.map((option) => (
              <SelectItem key={option.id} value={slugify(option.id)}>
                <span>{option.id}</span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Select
          value={model}
          onValueChange={(value) => handleModelChange(value)}
          onOpenChange={useTrackFilterCategoryClicked(FilterKey.MODELS)}
          disabled={brand === brandPlaceholder}
        >
          <SelectTrigger
            wrapperClassName="w-full"
            label={i18n.formatMessage('plp.model')}
            className="w-full"
          >
            <SelectValue
              placeholder={modelPlaceholder}
              className="body-16-regular"
            />
          </SelectTrigger>
          <SelectContent className="max-h-[340px]" withScrollDownButton>
            <SelectItem value={modelPlaceholder}>
              <span>{modelPlaceholder}</span>
            </SelectItem>
            {brands
              .find((brandItem) => slugify(brandItem.id) === brand)
              ?.models.map((option) => (
                <SelectItem key={option.id} value={slugify(option.id)}>
                  <span>{option.id}</span>
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>

      <CTAs primaryCTA={adjustedCTA} fullWidth />
    </div>
  );
};

const HeroFilters = (props: Props) => {
  const swrConfigValue = useMemo(() => {
    return {
      fallback: {
        [FILTERS_RESPONSE_SWR_FALLBACK_KEY]: filters['de-DE'],
      },
    };
  }, []);

  return (
    <SWRConfig value={swrConfigValue}>
      <PathFilterProvider basePathname={BASE_SUBSCRIPTION_PATH}>
        <HeroFilterComponent {...props} />
      </PathFilterProvider>
    </SWRConfig>
  );
};
export default HeroFilters;
