import { CancelOutlined } from '@finn/design-system/icons/cancel-outlined';
import { Check } from '@finn/design-system/icons/check';
import React from 'react';

import { HeroFeature } from './metaData';

type Props = {
  features: HeroFeature[];
};

const getIcon = (feature: HeroFeature) => {
  if (feature.icon.key === 'cross') {
    return <CancelOutlined width={24} height={24} />;
  } else {
    return <Check />;
  }
};

const Features: React.FC<Props> = ({ features }) => {
  if (!features || features.length === 0) return null;

  return (
    <ul className="mb-4 p-0 sm:mb-6">
      {features.slice(0, 3).map((feature) => (
        <li key={feature.feature} className="my-2 flex items-center">
          <div className="mr-2 flex items-center">{getIcon(feature)}</div>
          <p className="body-16-light">{feature.feature}</p>
        </li>
      ))}

      {features.slice(3, 6).map((feature) => (
        <li key={feature.feature} className="my-2 flex items-center">
          <div className="mr-2 flex items-center">{getIcon(feature)}</div>
          <p className="body-16-light">{feature.feature}</p>
        </li>
      ))}
    </ul>
  );
};
export default Features;
