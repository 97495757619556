import { useCurrentLocale } from '@finn/ui-utils';
import { memo, ReactNode, useMemo } from 'react';

import { PathFilterMapper } from '../../core';
import { useFiltersResponse } from '../hooks/useFilterResponse';
import { FilterProvider } from './lib';

type PathFilterProviderProps = {
  basePathname: string;
  isOutOfStock?: boolean;
  children?: ReactNode;
};

export const PathFilterProvider = memo(function PathFilterProvider({
  basePathname,
  isOutOfStock,
  children,
}: PathFilterProviderProps) {
  const { locale } = useCurrentLocale();
  const filtersResponse = useFiltersResponse(isOutOfStock);

  const filterMapper = useMemo(
    () => new PathFilterMapper(basePathname, locale, filtersResponse),
    [basePathname, filtersResponse, locale]
  );

  return (
    <FilterProvider filterMapper={filterMapper}>{children}</FilterProvider>
  );
});
